<template>
  <el-dialog
    :visible.sync="visible"
    :title="!dataForm.id ? $t('add') : $t('update')"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmitHandle()"
      label-width="120px"
    >
      <el-form-item prop="name" :label="$t('dept.name')">
        <el-input
          v-model="dataForm.name"
          :placeholder="$t('dept.name')"
        ></el-input>
      </el-form-item>
      <el-form-item
        prop="parentName"
        :label="$t('dept.parentName')"
        class="dept-list"
      >
        <el-popover
          v-model="deptListVisible"
          ref="deptListPopover"
          placement="bottom-start"
          trigger="click"
        >
          <el-tree
            :data="deptList"
            :props="{ label: 'name', children: 'children' }"
            node-key="id"
            ref="deptListTree"
            :highlight-current="true"
            :expand-on-click-node="false"
            accordion
            @current-change="deptListTreeCurrentChangeHandle"
          >
          </el-tree>
        </el-popover>
        <el-input
          v-model="dataForm.parentName"
          v-popover:deptListPopover
          :readonly="true"
          :placeholder="$t('dept.parentName')"
        >
          <i
            v-if="$store.state.user.superAdmin === 1 && dataForm.pid !== '0'"
            slot="suffix"
            @click.stop="deptListTreeSetDefaultHandle()"
            class="el-icon-circle-close el-input__icon"
          >
          </i>
        </el-input>
      </el-form-item>
      <el-form-item prop="sort" :label="$t('dept.sort')">
        <el-input-number
          v-model="dataForm.sort"
          controls-position="right"
          :min="0"
          :label="$t('dept.sort')"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <template slot="footer">
      <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
      <el-button type="primary" @click="dataFormSubmitHandle()">{{
        $t('confirm')
      }}</el-button>
    </template>
  </el-dialog>
</template>

<script>
import debounce from 'lodash/debounce';
export default {
  data() {
    return {
      visible: false,
      deptList: [],
      deptListVisible: false,
      dataForm: {
        id: '',
        name: '',
        pid: '',
        parentName: '',
        sort: 0,
      },
    };
  },
  computed: {
    dataRule() {
      return {
        name: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'blur',
          },
        ],
        parentName: [
          {
            required: true,
            message: this.$t('validate.required'),
            trigger: 'change',
          },
        ],
      };
    },
  },
  methods: {
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        this.getDeptList().then(() => {
          if (this.dataForm.id) {
            this.getInfo();
          } else if (this.$store.state.user.superAdmin === 1) {
            this.deptListTreeSetDefaultHandle();
          }
        });
      });
    },
    // 获取部门列表
    getDeptList() {
      return this.$http
        .get('/sys/org/list')
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.deptList = res.data;
        })
        .catch(() => {});
    },
    // 获取信息
    getInfo() {
      this.$http
        .get(`/sys/org/${this.dataForm.id}`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.dataForm = {
            ...this.dataForm,
            ...res.data,
          };
          if (this.dataForm.pid === '0') {
            return this.deptListTreeSetDefaultHandle();
          }
          this.$refs.deptListTree.setCurrentKey(this.dataForm.pid);
        })
        .catch(() => {});
    },
    // 上级部门树, 设置默认值
    deptListTreeSetDefaultHandle() {
      this.dataForm.pid = '0';
      this.dataForm.parentName = this.$t('dept.parentNameDefault');
    },
    // 上级部门树, 选中
    deptListTreeCurrentChangeHandle(data) {
      this.dataForm.pid = data.id;
      this.dataForm.parentName = data.name;
      this.deptListVisible = false;
    },
    // 表单提交
    dataFormSubmitHandle: debounce(
      function () {
        this.$refs['dataForm'].validate((valid) => {
          if (!valid) {
            return false;
          }
          this.$http[!this.dataForm.id ? 'post' : 'put'](
            '/sys/org/save',
            this.dataForm
          )
            .then(({ data: res }) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg);
              }
              this.$message({
                message: this.$t('prompt.success'),
                type: 'success',
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit('refreshDataList');
                },
              });
            })
            .catch(() => {});
        });
      },
      1000,
      { leading: true, trailing: false }
    ),
  },
};
</script>

<style lang="scss">
.mod-sys__dept {
  .dept-list {
    .el-input__inner,
    .el-input__suffix {
      cursor: pointer;
    }
  }
}
</style>
